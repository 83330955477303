<div class="signing-page__content" #content>
  <div class="signing-page__navigation" *ngIf="isPhoneWidth || isTabletWidth">
    <button (click)="pageNavi('up')">
      <img class="previous-button" alt="" />
    </button>
    <p>Page {{ pageNumber }} / {{ totalPages }}</p>
    <button (click)="pageNavi('down')">
      <img alt="" class="next-button" />
    </button>
  </div>
  <div
    class="signing-page__navigation"
    *ngIf="signingNow && status !== 'COMPLETED' && !isPhoneWidth && !isTabletWidth">
    <button (click)="pageNavi('up')">
      <img class="previous-button" alt="" />
    </button>
    <p>Page {{ pageNumber }} / {{ totalPages }}</p>
    <button (click)="pageNavi('down')">
      <img alt="" class="next-button" />
    </button>
  </div>

  <div
    class="signing-page__done_navigation"
    *ngIf="(status === 'COMPLETED' || !signingNow) && !isPhoneWidth && !isTabletWidth">
    <button (click)="print()">
      <img
        [style.maskImage]="
          'url(../../../assets//images/template-icons/printer.svg)'
        "
        alt="" />
    </button>
    <button (click)="zoomLevel('in')">
      <img
        [style.maskImage]="'url(../../../assets//images/icons/zoom-in.svg)'"
        alt="" />
    </button>
    <button (click)="zoomLevel('out')">
      <img
        [style.maskImage]="'url(../../../assets//images/icons/zoom-out.svg)'"
        alt="" />
    </button>
    <select [(ngModel)]="zoom">
      <option value="0.25">25%</option>
      <option value="0.5">50%</option>
      <option value="0.75">75%</option>
      <option value="1" selected>100%</option>
      <option value="1.25">125%</option>
      <option value="1.5">150%</option>
      <option value="1.75">175%</option>
      <option value="2">200%</option>
      <option value="2.25">225%</option>
      <option value="2.5">250%</option>
      <option value="2.75">275%</option>
      <option value="3">300%</option>
    </select>
    <div class="divider"></div>
    <button (click)="pageNavi('up')">
      <img
        [style.maskImage]="'url(../../../assets/images/icons/up.svg)'"
        alt=""
        class="up-button" />
    </button>
    <div class="page-text">{{ pageNumber }} of {{ totalPages }}</div>
    <button (click)="pageNavi('down')">
      <img
        [style.maskImage]="'url(../../../assets/images/icons/down.svg)'"
        alt=""
        class="down-button" />
    </button>
  </div>

  <div class="pdf">
    <div
      class="signing"
      [ngStyle]="{
        position: 'absolute',
        left: positionLeft,
        top: '10px',
        marginLeft: 'auto',
        marginRight: 'auto'
      }">
      <ko-stage [config]="stageConfig" #stage></ko-stage>
    </div>
    <pdf-viewer
      [src]="pdfSrc"
      [fit-to-page]="true"
      [zoom]="zoom"
      [(page)]="pageNumber"
      [show-borders]="true"
      [show-all]="false"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="test($event)">
    </pdf-viewer>
  </div>
</div>
