<!-- <app-spinner></app-spinner> -->

<div class="media-viewer-container">
  <div class="media-viewer-nav">
    <div class="left">
      <button (click)="goBack()" *ngIf="data.close">
        <img
          src="../../../assets//images/icons/back-white.svg"
          alt=""
          class="back-button" />
      </button>
      <img src="../../../assets//images/icons/media.svg" alt="" />
      <span class="title">{{ data.data.title }}</span>
    </div>
    <div class="right">
      <button matRipple class="share-button" (click)="openModal()" *ngIf="data.close">
        <img src="../../../assets//images/icons/share-white.svg" alt="" />
      </button>
      <button matRipple class="cta-buttons" (click)="download()">
        <img src="../../../assets//images/icons/download-white.svg" alt="" />
        <p>Download</p>
      </button>
    </div>
  </div>

  <video
    width="1000px"
    controls
    autoplay
    [src]="videoBlobUrl"
    type="video/mp4"></video>
</div>
