<div [class]="_navigation.lastKnownLocation === '/maintainance' ? 'wrapper-maintainance' : 'wrapper'">
  <div class="content-wrapper">
    <ng-content></ng-content>
  </div>
  <p class="copyright">
    <a
    class="emphasize"
    href="https://signsecureapp.unawa.asia/terms-and-conditions"
    target="_blank">Terms & Conditions</a> | <a
    class="emphasize"
    href="https://signsecureapp.unawa.asia/privacy-policy"
    target="_blank">Privacy Policy</a><br />
    © 2024 <a
    class="emphasize"
    href="https://www.unawa.asia/"
    target="_blank">UNAWA</a>. All rights reserved
  </p>
</div>
