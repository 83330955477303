import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import QRCode from 'qrcode'
import { environment } from 'src/environments/environment';
import QRCodeStyling from "qr-code-styling";
import { FilesService } from 'src/app/@shared/services/files.service';


@Injectable({
  providedIn: 'root'
})
export class QrService {

  readonly key = environment.qrCodeKey ?? 'thisissorandom'
  constructor(
    private _fileService: FilesService
  ) { }

  encrypt(value: string): string {
    console.log({ something: this.key })
    return CryptoJS.AES.encrypt(value, this.key.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    console.log({ something: this.key })
    return CryptoJS.AES.decrypt(textToDecrypt, this.key.trim()).toString(CryptoJS.enc.Utf8);
  }

  async getQRCode(text: string) {
    const qrCode = new QRCodeStyling({
      width: 600,
      height: 600,
      data: text,
      margin: 12,
      image: '../../../assets/images/unawa-logo.png',
      dotsOptions: {
        color: '#232323',
        type: 'dots',
      },
      backgroundOptions: {
        color: '#ffffff',
      },
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 18,
        imageSize: 0.4,
        hideBackgroundDots: true,
      },
      cornersSquareOptions: {
        type: 'dot',
        color: '#232323',
      },
      cornersDotOptions: {
        type: 'dot',
        color: '#232323',
      },
    });

    try {
      const blob = await qrCode.getRawData('png');
      if (blob) {
        const image = await this._fileService.blobToBase64(blob);
        return image.changingThisBreaksApplicationSecurity
      } else {
        console.error('Failed to get blob data');
        return null
      }
    } catch (error) {
      return null
      console.error('Error:', error);
    }
  }


  getQRCodeConfig(position: string, height: number, width: number) {
    switch (position) {
      case 'top-right': return { x: width - 68 - 28, y: height - (35 + 55) }
      case 'bottom-right': return { x: width - 68 - 28, y: 35 }
      case 'bottom-left': return { x: 68, y: 35 }
      case 'top-left': return { x: 68, y: height - (35 + 55) }
      case 'top-center': return { x: (width / 2) - 28, y: height - (35 + 55) }
      case 'bottom-center': return { x: (width / 2) - 28, y: 35 }
      default: return { x: 68, y: 35 }
    }
  }
}
