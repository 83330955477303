import { DatePipe, formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  lastValueFrom,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';

interface WorkFlow {
  signOrder?: boolean;
  parties?:
    | {
        name?: string;
        email?: string;
        id?: string;
        role?: string;
      }[]
    | any[];
  emailContent?: {
    subject?: string;
    message?: string;
  };
  signatures?: any[];
  reminders?: {
    expiration?: string;
    reminderInXDays?: number;
    reminderEveryXDays?: number;
    reminderBeforeXDays?: number;
  };
  status?: string;
  auditTrail?: any[];
  qrPosition?: string;
  currentParty?: string;
  currentOrder?: number;
}
interface WorkFlowDTO {
  id?: any;
  file?: any;
  fileData?: any;
  name?: string;
  type?: string;
  description?: string;
  ip?: string;
  workflow?: WorkFlow;
  createdBy?: string;
  timezone?: string;
  loginType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SignSecureService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly directory = environment.apiConfig.directory.url;
  private readonly signsecureUrl = `${this.baseUrl}${this.directory}/signsecure`;
  private readonly dirUrl = `${this.baseUrl}${this.directory}`;
  private signSecureProgress = new BehaviorSubject<Number>(1);
  signSecureProgress$ = this.signSecureProgress.asObservable();

  signatureType: number = 0;
  loginType: any = null;
  signatureId: string = '';

  signatureTypeText: string = ''
  signatureId_: string = ''

  private signSecurePage = new BehaviorSubject<Number>(1);
  signSecurePage$ = this.signSecurePage.asObservable();

  private worflowData = new BehaviorSubject<WorkFlowDTO>({});
  worflowData$ = this.worflowData.asObservable();

  publicFile: any;
  private isPublicView = new BehaviorSubject<boolean>(false);
  isPublicView$ = this.isPublicView.asObservable();

  signatureOptions = [
    {
      name: 'Signature',
      image: '../../../../../assets/images/template-icons/signature.svg',
      type: 'signature',
    },
    {
      name: 'Signature & Full Name',
      image: '../../../../../assets/images/template-icons/signature.svg',
      type: 'signature-name',
    },
    {
      name: 'Signature & Date',
      image: '../../../../../assets/images/template-icons/signature.svg',
      type: 'signature-date',
    },
    {
      name: 'Signature, Full Name & Designation',
      image: '../../../../../assets/images/template-icons/signature.svg',
      type: 'signature-name-designation',
    },
    {
      name: 'Full Name',
      image: '../../../../../assets/images/template-icons/text.svg',
      type: 'name',
    },
    {
      name: 'Initial',
      image: '../../../../../assets/images/template-icons/pen.svg',
      type: 'initials',
    },
    {
      name: 'Designation',
      image: '../../../../../assets/images/template-icons/designation.svg',
      type: 'designation',
    },
    {
      name: 'Date & Time',
      image: '../../../../../assets/images/template-icons/calendar.svg',
      type: 'date-time',
    },
    // {
    //   name: 'Textbox',
    //   image: '../../../../../assets/images/template-icons/box.svg',
    //   type: 'textbox',
    // },
  ];

  signPdfData: any;
  signPdfName: string = '';

  private addFieldPdfPageNumber = new BehaviorSubject<Number>(1);
  addFieldPdfPageNumber$ = this.addFieldPdfPageNumber.asObservable();

  private addFieldPdfZoom = new BehaviorSubject<number>(1);
  addFieldPdfZoom$ = this.addFieldPdfZoom.asObservable();

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthenticationService
  ) {}

  nextProgress(progress: number) {
    this.signSecureProgress.next(progress);
    this.signSecurePage.next(progress);
  }

  prevPage(progress: number) {
    this.signSecurePage.next(progress);
  }

  setInitialData(value: any, fileData: any) {
    this.worflowData.next({ ...value, fileData });
  }

  pendingSignature(directory: any) {
    console.log({ directory });
    localStorage.setItem('pendingData', JSON.stringify(directory));
  }

  doneSignature() {
    localStorage.removeItem('pendingData');
  }

  havePendingSignature() {
    return localStorage.getItem('pendingData');
  }

  setId(id: string) {
    const value = this.worflowData.value;
    this.worflowData.next({ ...value, id });
  }

  setFile(file: any, fileData: any, name = undefined) {
    const value = this.worflowData.value;
    this.worflowData.next({
      ...value,
      file,
      fileData,
      type: 'file',
      name: name || (file?.name ?? ''),
    });
  }

  setFileInfo(name: string, description: string) {
    const ipInfo: any = localStorage.getItem('ipInfo');
    const ipInfoParse: any = JSON.parse(ipInfo);
    const value = this.worflowData.value;
    console.log({ value, name, description });
    this.worflowData.next({
      ...value,
      name,
      description,
      ip: ipInfoParse?.ip,
      timezone: ipInfoParse?.timezone,
    });
  }

  isFileUploaded() {
    const value = this.worflowData.value;
    return !!value.file;
  }

  setPartiesData(parties: any, signOrder: boolean) {
    const value = this.worflowData.value;
    const formattedparties = parties.map((party: any, index: number) => ({
      ...party,
      order: index,
    }));
    const data = {
      ...value,
      workflow: { ...value.workflow, parties: formattedparties, signOrder },
    };
    this.worflowData.next(data);
    this.checkSignatureWithCurrentParty(data);
  }

  clearWorkFlowData() {
    this.nextProgress(1);
    this.worflowData.next({});
  }

  setSignatures(signatures: any, qrPosition: any) {
    const value = this.worflowData.value;
    this.worflowData.next({
      ...value,
      workflow: { ...value.workflow, signatures, qrPosition },
    });
  }

  checkSignatureWithCurrentParty(currentData: any) {
    const { workflow } = currentData;

    if (!workflow) return;

    const { parties, signatures } = workflow;

    if (!signatures || !parties) {
      return;
    }

    // check if party is removed
    signatures.forEach((page: any[], index: number) => {
      if (page.length > 0) {
        signatures[index] = page.filter(signature =>
          parties.some(
            (party: any) => party.id === signature.id && party.role === 'SIGN'
          )
        );
      }
    });

    // check if parties' name is updated
    signatures.forEach((page: any[], index: number) => {
      if (page.length > 0) {
        signatures[index] = page.map(signature => {
          if (
            ['name', 'designation'].some(type => signature.type.includes(type))
          ) {
            const party = parties.find(
              (party: any) => party.id === signature.id
            );
            const nameConfig = signature.nameConfig;
            const desginationConfig = signature.desginationConfig;

            if (party.designation && desginationConfig && Object.keys(desginationConfig).length > 1) {
                desginationConfig.text = party.designation;
            }

            return {
              ...signature,
              nameConfig: {
                ...nameConfig,
                text: party.name,
              },
              desginationConfig: {
                ...desginationConfig,
              },
            };
          } else {
            return signature;
          }
        });
      }
    });

    this.worflowData.next({
      ...currentData,
      workflow: { ...workflow, signatures: signatures },
    });
  }

  createWorkflow(signsecure: any) {
    return this._httpClient.post<any>(
      `${this.signsecureUrl}/create`,
      this.formatData(signsecure)
    );
  }

  formatData(data: any) {
    let formData = new FormData();
    if (data.file) {
      formData.append('file', data.file);
      delete data?.file;
      delete data?.fileData;
    }

    if (data?.workflow?.signOrder !== undefined) {
      formData.append(
        'workflow[signOrder]',
        data?.workflow?.signOrder ?? false
      );
    }

    if (data?.workflow?.signature) {
      const sigs = data['workflow']['signature'] ?? [];
      data['workflow']['signature'] = sigs.map((sig: any[]) => {
        if (sig.length === 0) {
          sig.push({ trackId: 0 });
        } else {
          sig.map(s => {
            const { image, ...data } = s;
            return data;
          });
        }
        return sig;
      });
    }
	
    formData = this.obj2FormData(data, formData);
    return formData;
  }

	async callIpify(): Promise<any> {
		const API_IP_URL = environment.integration.ipifyURL;
	
		try {
			const response:any = await lastValueFrom(this._httpClient.get(API_IP_URL));
			return {
				ip: response.ip,
				city: "",
				region: "",
				country: "",
				timezone: ""
			};
		} catch (error: any) {
			console.error('Error fetching IP info:', error.message);
			return null;
		}
	}

	// async callIpInfo(): Promise<any> {
	// 	const API_IP_URL = environment.integration.ipInfoURL;
	// 	const API_TOKEN = environment.integration.ipInfoApiToken;
		
	// 	try {
	// 		if (!API_TOKEN) {
	// 		throw new Error('API token not provided.');
	// 		}
		
	// 		const response = await lastValueFrom(this._httpClient.get(API_IP_URL, {
	// 		headers: {
	// 			Authorization: `Bearer ${API_TOKEN}`,
	// 		},
	// 		}));
	// 		console.log(response)
		
	// 		return response;
	// 	} catch (error: any) {
	// 		console.error('Error fetching IP info:', error.message);

	// 		return null;
	// 	}
	// }

  formatCompleteData(data: any) {
    let formData = new FormData();
    if (data.file) {
      formData.append('files', data.file);
      formData.append('files', data?.manifest);
      formData.append('files', data?.document);

      delete data?.manifest;
      delete data?.document;
      delete data?.file;
      delete data?.fileData;
    }

    formData = this.obj2FormData(data, formData);
    return formData;
  }

  obj2FormData(obj: any, formData = new FormData()) {
    const createFormData = (obj: any, subKeyStr = '') => {
      for (let i in obj) {
        let value = obj[i];
        let subKeyStrTrans = subKeyStr ? subKeyStr + '[' + i + ']' : i;
        if (typeof value === 'string' || typeof value === 'number') {
          formData.append(subKeyStrTrans, value as any);
        } else if (
          subKeyStrTrans.includes('[signatures][') &&
          Array.isArray(value) &&
          value.length === 0
        ) {
          formData.append(`${subKeyStrTrans}[0][trackId]`, value as any);
        } else if (typeof value === 'object') {
          createFormData(value, subKeyStrTrans);
        }
      }
    };

    createFormData(obj);

    return formData;
  }

  setWorkFlowData(data: any) {
    this.worflowData.next(data);
  }

  getSignatoryOptions(signatory: any) {
    return this.signatureOptions.map((option: any) => {
      return {
        ...option,
        disabled:
          !signatory?.designation && option.type.includes('designation'),
        disabledAll: signatory?.role !== 'SIGN',
      };
    });
  }

  async getSavedWorkflowData() {
    console.log('retrieve data ', localStorage.getItem('workflow'));

    const data = !!localStorage.getItem('workflow')
      ? JSON.parse(localStorage.getItem('workflow') ?? '')
      : '';

    if (!!!data) return data;

    return data;
  }

  clearSavedData() {
    localStorage.removeItem('workflow');
  }

  signatureTypeIndexToText(index: number): string {
    switch (index) {
      case 0:
        return 'Draw';
      case 1:
        return 'Upload';
      case 2:
        return 'Choose';
      case 3:
        return 'Type';
      default:
        return '';
    }
  }

  documentSigned(data: any) {
    const updatedData = this.setIpInfo(data);
    return this._httpClient.post<any>(
      `${this.signsecureUrl}/sign/${data.id}`,
      this.formatData(updatedData)
    );
  }

  documentDone(data: any) {
    return this._httpClient.post<any>(
      `${this.signsecureUrl}/complete/${data.id}`,
      this.formatCompleteData(data)
    );
  }

  bytesToFile(data: any, filename: string): File {
    return new File([data], this.getCorrectFilename(filename), {
      type: 'application/pdf',
    });
  }

  getCorrectFilename(name: string = '') {
    return name.match(/^.*\.[^\\]+$/g)?.length ?? 0 > 0 ? name : `${name}.pdf`;
  }

  setAddFieldPdfNumber(number: number) {
    this.addFieldPdfPageNumber.next(number);
  }

  setAddFieldZoom(number: number) {
    this.addFieldPdfZoom.next(number);
  }

  documentDecline(id: string, reason: string) {
    return this._httpClient.post<any>(`${this.signsecureUrl}/decline/${id}`, {
      reason,
    });
  }

  documentReject(id: string, note: string) {
    return this._httpClient.post<any>(`${this.signsecureUrl}/reject/${id}`, {
      reason: note,
    });
  }

  documentCancel(id: string) {
    return this._httpClient.post<any>(`${this.signsecureUrl}/cancel/${id}`, {
      reason: '',
    });
  }

  documentView(id: string) {
    return this._httpClient.post<any>(`${this.signsecureUrl}/view/${id}`, {});
  }

  documentApprove(id: string, note: string) {
    const data = { reason: note };
    return this._httpClient.post<any>(
      `${this.signsecureUrl}/approve/${id}`,
      this.setIpInfo(data)
    );
  }

  getReadableRole(role: string) {
    switch (role) {
      case 'SIGN':
        return 'Needs to Sign';
      case 'APPROVE':
        return 'Needs to Approve';
      case 'APPROVER':
        return 'Needs to Approve';
      case 'COPY':
        return 'Receives a Copy';
    }

    return ' ';
  }

  setPublicFile(file: string) {
    this.publicFile = file;
    this.isPublicView.next(true);
  }

  hidePublicView() {
    this.isPublicView.next(false);
  }

  updateReminders(data: any) {
    const value = this.worflowData.value;
    let formatData = data;
    if (data) {
      formatData.expiration = data.expiration.format('MM-DD-YYYY');
      formatData.reminderInXDays = data.reminderInXDays?.toString();
      formatData.reminderEveryXDays = data.reminderEveryXDays?.toString();
      formatData.reminderBeforeXDays = data.reminderBeforeXDays?.toString();
    }

    this.worflowData.next({
      ...value,
      workflow: { ...value.workflow, reminders: formatData },
    });
  }

  getProxyUrl(id: string) {
    return this._httpClient.get<any>(`${this.dirUrl}/proxy/${id}`, {
      responseType: 'blob' as 'json',
    });
  }

  getSignaturesProxy(id: string) {
    return this._httpClient.get<any>(`${this.baseUrl}/signature/proxy/${id}`, {
      responseType: 'blob' as 'json',
    });
  }

  formatWorkflowData(data: any) {
    const datePipe = new DatePipe('en-US');

    return data
      .filter((datum: any) => datum.workflow)
      .map((datum: any) => {
        const actions = { delete_doc: undefined, download: undefined };
        let dateCreated, dateFormatted;
        if (
          (datum?.workflow?.status === 'CANCELLED' ||
            datum?.workflow?.status === 'DECLINED' ||
            datum?.workflow?.status === 'REJECTED' ||
            datum?.workflow?.status === 'EXPIRED') &&
          datum.createdBy === this._authService.userId
        ) {
          actions['delete_doc'] = datum.id;
        } else {
          delete actions.delete_doc;
        }

        if (datum?.workflow?.status === 'COMPLETED') {
          actions['download'] = datum;
        } else {
          delete actions.download;
        }

        if (datum?.createdAtFormatted) {
          dateCreated = datum.createdAtFormatted.split(',')[0];
        } else {
          dateCreated = datePipe.transform(
            new Date(datum?.createdAt),
            'dd MMM YYYY'
          );
        }

        if (datum?.updatedAtFormatted) {
          dateFormatted = datum.updatedAtFormatted.split(',')[0];
        } else {
          dateFormatted = datePipe.transform(
            new Date(datum?.updatedAt),
            'dd MMM YYYY'
          );
        }

        let status = datum.workflow.status;
        const party = datum.workflow.parties.find(
          (party: any) => party.id === this._authService.userId
        );
        if (party?.viewedFormatted && status === 'PENDING') {
          status = 'VIEWED';
        }

        return {
          ...datum,
          title: datum.name,
          status: status,
          date_created: dateCreated,
          last_updated: dateFormatted,
          workflow: datum.workflow,
          actions,
        };
      });
  }

  async callIpInfo(): Promise<any> {
    const API_IP_URL = environment.integration.ipInfoURL;
    const API_TOKEN = environment.integration.ipInfoApiToken;

    try {
      if (!API_TOKEN) {
        throw new Error('API token not provided.');
      }

      const response = await lastValueFrom(
        this._httpClient.get(API_IP_URL, {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          },
        })
      );

      return response;
    } catch (error: any) {
      console.error('Error fetching IP info:', error.message);

      return null;
    }
  }

  saveWorkflowData(partiesLength: any = undefined) {
    console.log('saveworkflowdata', this.worflowData.value);
    localStorage.setItem(
      'workflow',
      JSON.stringify({ ...this.worflowData.value, partiesLength })
    );
  }

  setIpInfo(data: any) {
    const ipInfo: any = localStorage.getItem('ipInfo');
    const ipInfoParse: any = JSON.parse(ipInfo);
    data.ip = ipInfoParse?.ip;
    data.regionCity = `${ipInfoParse?.region} / ${ipInfoParse?.city}`;
    data.loginType = this.loginType || localStorage.getItem('loginType');
    data.signatureType = this.signatureTypeText;
    data.signatureId = this.signatureId_;
    return data;
  }
}
