<div class="user-groups margin-container">
  <span class="header">User Groups</span>
  <app-table
    [title]="'User Groups'"
    [addButton]="'Add New Group'"
    [columns]="userGroupsCols"
    [data]="userGroups | async"
    [type]="'mUserGrp'"
    (modal)="showModal($event)"
  >
    <div class="tableSearch">
      <div class="search-form">
        <mat-form-field floatLabel="never" appearance="fill">
          <input
            matInput
            type="text"
            placeholder="Input Your Text in Here"
            [(ngModel)]="searchQuery"
            (keydown.enter)="search()"
          />
          <span matPrefix
            ><img
              class="search-image"
              src="../../../assets/images/icons/search.png"
              alt=""
          /></span>
        </mat-form-field>
        <button type="submit" class="search-button" (click)="search()">
          Search
        </button>
      </div>
    </div>
    </app-table>
</div>
