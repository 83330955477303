import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DashboardCardModel } from './@shared/models/dasboard.model';
import { AuthenticationService } from './@shared/services/authentication.service';
import { BreadcrumbsService } from './@shared/services/breadcrumbs.service';
import { DashBoardServices } from './@shared/services/dashboard.service';
import { FileActionService } from './@shared/services/file-action.service';
import { FilesService } from './@shared/services/files.service';
import { LoaderService } from './@shared/services/loader.service';
import { NavigationService } from './@shared/services/navigation.service';
import { SettingsService, ThemeSettings } from './@shared/services/settings.service';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'unawa-dms-UI';
  initialLoad: boolean = true;
  userType: string = '';
  viewPdf: boolean = false;
  viewImage: boolean = false;
  pdfSrc: string = '';
  fileType: string = '';

  primaryColor: string = '';
  menuBackgroundColor: string = '';
  backgroundColor: string = '';
  textColor: string = '';
  topbarColor: string = '';
  hoverColor: string = '';
  bc = new BroadcastChannel('idle_channel');
  sessionTimer: any;

  theme: ThemeSettings = {
    logo: '',
    primaryColor: '',
    menuBackgroundColor: '',
    backgroundColor: '',
    textColor: '',
    topbarColor: '',
    hoverColor: '',
    headingFont: '',
    subheadingFont: '',
    bodyFont: ''
  };

  private timerSubscription: Subscription | undefined;

  constructor(
    private _authService: AuthenticationService,
    private _dashboardService: DashBoardServices,
    private _settingsService: SettingsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.checkForMaintenance()
    this.startTimer();

    this._settingsService
      .getSettings()
      .subscribe((data: any) => {
        const settings = data.data
        this.theme.primaryColor = settings.primaryColor;
        this.theme.menuBackgroundColor = settings.backgroundColor;
        this.theme.backgroundColor = settings.menuBackgroundColor;
        this.theme.textColor = settings.textColor;
        this.theme.topbarColor = settings.linkColor ;
        this.theme.logo = settings.companyLogo
        this.theme.hoverColor = settings.hoverColor
        this._settingsService.setTheme(this.theme);
      });

    this._dashboardService.getAppVersion().subscribe((i) => {
      this._dashboardService.appVersion = i.data.version;
    });

    this._authService.isAuthorized().subscribe(({ data }) => {
      if (data) {
        this._authService.isLogin();
      }
    });

    this._settingsService.updateTheme$.subscribe((theme) => {
      this._settingsService.updateLogo(theme.logo ?? this._settingsService.logo)
      this.primaryColor = `--primary-color: ${theme.primaryColor};`;
      this.menuBackgroundColor = `--secondary-color: ${theme.menuBackgroundColor};`;
      this.backgroundColor = `--background-color: ${theme.backgroundColor};`;
      this.textColor = `--text-color: ${theme.textColor};`;
      this.topbarColor = `--topbar-color: ${theme.topbarColor};`;
      this.hoverColor = `--hover-color: ${theme.hoverColor};`;
    });

  }

  private checkForMaintenance() {
    this._authService.maintainance().subscribe((data: any) => {
      if (data.data.isMaintenance) {
        this._authService.logout()
        this.router.navigate(['maintainance']);
      }
    });
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  private startTimer() {
    this.timerSubscription = interval(30000)
      .subscribe(() => {
        this.checkForMaintenance()
        console.log('Timer triggered');
      });
  }

  private stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
