<div class="list-folder">
  <h4 class="title">Folder and Files</h4>
  <app-document-tree (OpenFolder)="clearSelection()"></app-document-tree>
  <div class="fill-flex">
    <app-table
      [title]="'Folders and Files'"
      [hasCheckBox]="hasCheckBox"
      [columns]="filesCols"
      [canOpenFile]="true"
      (modal)="createFolder($event)"
      [addButton]="'Create Folder'"
      [type]="'create-folder'"
      [paginate]="true"
      [pageSize]="50"
      [showBreadcrumbs]="true"
      [data]="filesData | async"
      [folderFunctions]="folderFunctions"
      [selection]="selection"
      [breadcrumbBack]="onFolderBack"
      [rowFunction]="openFolder"
      [nameFunction]="openPdf"
      (modalCloses)="modalCloses($event)"
      (selectedItems)="selectedIds=$event"
      [isChecked]="isAllChecked"

    >
      <div class="extra-buttons" *ngIf="multipleFileActionsFlag">
        <button matRipple class="extra-button" (click)="bulkDownload()">
          Download
        </button>
        <button matRipple class="extra-button" (click)="deleteBulkFiles()">Delete</button>
        <button matRipple class="extra-button" (click)="moveBulk()">Move</button>
        <!-- <button matRipple class="extra-button">Duplicate</button> -->
      </div>
    </app-table>
  </div>
</div>
