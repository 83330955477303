import { Component, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../@shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-maintainance',
  templateUrl: './maintainance.component.html',
  styleUrls: ['./maintainance.component.sass'],
})
export class MaintainanceComponent implements OnInit {
  isTabletWidth = false;
  isPhoneWidth = false;

  constructor(
    private _authService: AuthenticationService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (screen.width >= 280 && screen.width <= 600) {
      this.isPhoneWidth = true;
    } else if (screen.width >= 600 && screen.width <= 1368) {
      this.isTabletWidth = true;
    } else {
      this.isPhoneWidth = false;
      this.isTabletWidth = false;
    }
    this.checkForMaintenance()
  }

  private checkForMaintenance() {
    this._authService.maintainance().subscribe((data: any) => {
      if (!data.data.isMaintenance) {
        this._authService.logout()
        this.router.navigate(['login']);
      }
    });
  }
}