<div class="audit-logs margin-container">
  <span class="header">Audit Log</span>
  <app-table
    [title]="'Audit Log'"
    [addButton]="'Download Log'"
    [addButtonIcon]="'../../../assets/images/icons/plus.png'"
    (modal)="downloadLog()"
    [columns]="auditCols"
    [showFilter]="true"
    [data]="auditData | async"
    [paginate]="true"
  ></app-table>
</div>
