import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { startWith, map, Observable } from "rxjs";
import { AuthenticationService } from "src/app/@shared/services/authentication.service";
import { UsersService } from "src/app/@shared/services/users.service";
import { SnackbarComponent } from "src/app/components/snackbar/snackbar.component";
import { SignSecureService } from "src/app/sign-secure/services/sign-secure.service";
import { AlreadyExistValidator } from "src/app/sign-secure/validators/already-exist.validator";

@Component({
	selector: "app-template-parties",
	templateUrl: "./template-parties.component.html",
	styleUrls: ["./template-parties.component.sass"],
})
export class TemplatePartiesComponent implements OnInit, OnDestroy {
	parties: any[] = [this.getEmptyParty()];
	users: any;

	emailControls: FormControl<any>[] = [];
	filteredOptions: Observable<string[]>[] = [];
	signOrder: boolean = true;
	length: number = 0;

	included = false
	constructor(
		private _signsecure: SignSecureService,
		private _usersService: UsersService,
		private _auth: AuthenticationService,
		private _snackBar: MatSnackBar
	) { }

	ngOnInit(): void {
		this._usersService.getUsers().subscribe((value) => {
			this.users = value.data;
		});

		this._signsecure.worflowData$.subscribe((value) => {
			this.parties = value.workflow?.parties ?? [this.getEmptyParty()];

			this.included = this.parties.some((party) => party.id === this._auth.userId)

			this.signOrder = value.workflow?.signOrder ?? true;
		});

	}

	ngOnDestroy(): void {
		this._signsecure.setPartiesData(
			this.parties.map((party, index) => ({ ...party, order: index + 1 })),
			this.signOrder
		);
	}



	addParty() {
		this.parties = [...this.parties, this.getEmptyParty()];
	}

	removeParty(index: number) {
		this.parties.splice(index, 1);
		this.length--;
	}

	select(user: any, index: number) {
		const party = this.parties[index];

		console.log({user, index, party})
		let mUser = user

		if (!!!mUser) {
			mUser = party.control.value
		}

		const emails =
			this.parties?.reduce((prev, party, i) => {
				if (i !== index) prev.push(party.email)
				return prev
			}, []) ?? []

		party["id"] = mUser.id;
		party["name"] = `${mUser.givenName} ${mUser.lastName}`;
		party["email"] = mUser.email
		party["control"].setValue(mUser.email)

		this.recheckFields(emails, index)

		party["role"] = "SIGN";
		party["designation"] = mUser.position;
		this.length++;
	}

	getEmptyParty() {
		const color = this.getRandomColor();

		const party = {
			id: "",
			name: "",
			email: "",
			role: "",
			designation: "",
			color: color,
			control: {},
			options: {},
			number: (this.parties?.length ?? 0) + 1,
		};

		const emails = this.parties?.map((party) => party.email) ?? [];
		const control = new FormControl<string>("", [
			AlreadyExistValidator(emails),
		]);

		control.patchValue("");

		party["control"] = control;
		party["options"] = this.getAutoCompleteValueChanage(control);

		return party;
	}

	private getAutoCompleteValueChanage(
		formControl: FormControl
	): Observable<string[]> {
		return formControl.valueChanges.pipe(
			startWith(""),
			map((value) =>
				this.users?.filter((data: any) =>
					data.email.toLowerCase().includes(value || "")
				)
			)
		);
	}

	drop(event: CdkDragDrop<any>) {
		moveItemInArray(this.parties, event.previousIndex, event.currentIndex);
	}

	addMeAsSignatory(add: boolean) {
		console.log(add,this.parties.some((party) =>{console.log(party); party.id === this._auth.userId;}));
		if (!add || this.parties.some((party) => party.id === this._auth.userId))
			return;

		const color = this.getRandomColor();

		const party = {
			id: this._auth.userId,
			name: this._auth.name,
			email: this._auth.email,
			role: "SIGN",
			designation: this._auth.designation,
			color: color,
			control: {},
			options: {},
		};
		console.log(party);
		const control = new FormControl<string>("");
		control.patchValue(this._auth.email);
		party["control"] = control;
		party["options"] = this.getAutoCompleteValueChanage(control);

		if (this.parties[0].email === "") {
			this.parties[0] = party;
		} else {
			this.parties = [party, ...this.parties];
		}
		this.length++;
	}

	back() {
		this._signsecure.setPartiesData(
			this.parties.map((party, index) => ({ ...party, order: index + 1 })),
			this.signOrder
		);
		this._signsecure.nextProgress(2);
	}

	next() {
		if (this.parties.some((party) => party.email === "" || party.name === "")) {
			this._snackBar.openFromComponent(SnackbarComponent, {
				duration: 3000,
				data: {
					type: "error",
					text: "Please enter the missing data",
				},
			})
			return
		}

		if (this.parties.some((party) => !!Object.keys(party.control?.errors ?? {})?.length)) {
			this._snackBar.openFromComponent(SnackbarComponent, {
				duration: 3000,
				data: {
					type: "error",
					text: "Please fix field errors",
				},
			})
			return
		}

		if (!this.parties.some((party) => party.role === "SIGN")) {
			this._snackBar.openFromComponent(SnackbarComponent, {
				duration: 3000,
				data: {
					type: "error",
					text: "Please add signatory",
				},
			})
			return
		}

		this._signsecure.setPartiesData(
			this.parties.map((party, index) => ({ ...party, order: index + 1 })),
			this.signOrder
		)
		this._signsecure.nextProgress(4)
	}

	getRandomColor() {
		var letters = "56789BCDE".split("");
		var color = "#";
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * letters.length)];
		}
		return color;
	}

	recheckFields(emails: string[], location: number) {
		console.log({ emails })
		this.parties.forEach((party, index) => {
			if (!!party.email) {
				let mEmails = [...emails]
				if (index !== location) {
					const loc = mEmails.findIndex(email => email === party.email)
					if (loc >= 0) {
						mEmails.splice(loc, 1)
					}
				}


				party["control"].clearValidators()
				party["control"].addValidators(AlreadyExistValidator(mEmails))
				party['control'].updateValueAndValidity()

				if (mEmails.includes(party.email)) {
					party["control"].setErrors({
						alreadyExist: { value: "Duplicate Email" },
					})
				} else {
					party["control"].setErrors({})
				}
			}
		})
	}
}
