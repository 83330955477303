<div class="inbox margin-container" *ngIf="!isPhoneWidth && !isTabletWidth; else mobileTable">
  <app-table
    *ngIf="!(viewMsg | async)"
    [title]="'Inbox'"
    [hasCheckBox]="hasCheckBox"
    [columns]="columns"
    [data]="data | async"
    [type]="'inbox'"
    [rowFunction]="onClickRow"
    [isFolderStructure]="false"
    [selection]="selection"
  >
    <div class="extra-buttons">
      <button matRipple class="extra-button" (click)="deleteMessages()">
        Delete selected messages
      </button>
    </div>
  </app-table>
  <app-inbox-view
    *ngIf="viewMsg | async"
    (goBackEvent)="goback($event)"
  ></app-inbox-view>
</div>

<ng-template #mobileTable>
  <div class="mobile-table">
    <app-tablet-table
      *ngIf="!(viewMsg | async)"
      [itemTemplate]="inbox_row"
      [title]="'Inbox'"
      [data]="data | async"
    ></app-tablet-table>
    <app-inbox-view
      *ngIf="viewMsg | async"
      (goBackEvent)="goback($event)"
    ></app-inbox-view>
  </div>
</ng-template>

<ng-template #inbox_row let-data>
  <div class="inbox-row" (click)="onClickRow(null, data)">
    <div class="inbox-content">
      <p class="title">{{ data.name }}</p>
      <p class="subtitle">{{ data.subject }}</p>
      <p class="content">{{ data.message }}</p>
    </div>
    <div class="inbox-date">
      <p class="content">{{ convertDate(data.date_modified) }}</p>
    </div>
  </div>
</ng-template>