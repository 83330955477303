<div>
  <mat-card class="container-login">
    <div class="flexGrid">
      <h2 class="heading2">Welcome back</h2>
      <h4 class="heading4">Enter your email and password below</h4>
    </div>
    <form name="loginForm" [formGroup]="loginForm">
      <div class="flex padding-top20">
        <mat-label class="label">Email</mat-label>
      </div>
      <div class="flex padding-left">
        <div class="form login-form">
          <mat-form-field
            floatLabel="never"
            appearance="fill">
            <input
              type="email"
              placeholder="Enter Email Here"
              matInput
              formControlName="username"
              (ngModelChange)="errorMsg = ''"
              [ngClass]="
                (loginForm.controls['username'].invalid || errorMsg) &&
                !loginForm.controls['username'].value &&
                (loginForm.controls['username'].touched || loginForm.controls['username'].dirty)
                  ? 'textfieldRegInvalid'
                  : 'textfieldReg'
              "
              (keyup)="keyLogin($event)" />
          </mat-form-field>
          <ng-container *ngIf="loginForm.controls['username']">
            <mat-error
              *ngIf="
                loginForm.controls['username'].value?.length <= 0 &&
                (loginForm.controls['username'].touched || loginForm.controls['username'].dirty)
              "
              >Email Address is Required</mat-error
            >
            <mat-error
              *ngIf="
                loginForm.controls['username'].invalid &&
                loginForm.controls['username'].value?.length > 0
              "
              >Email Address format is invalid</mat-error
            >
            <mat-error
              class="longError"
              *ngIf="
                errorMsg && loginForm.controls['username'].value?.length > 0
              "
              >{{ errorMsg }}</mat-error
            >
          </ng-container>
        </div>
      </div>
      <div class="flex padding-top20">
        <mat-label class="label">Password</mat-label>
      </div>
      <div class="flex padding-left">
        <div class="form login-form password">
          <mat-form-field
            floatLabel="never"
            appearance="fill">
            <input
              [type]="hide ? 'password' : 'text'"
              placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
              matInput
              formControlName="password"
              [ngClass]="
                loginForm.controls['password'].invalid &&
                (loginForm.controls['password'].touched || loginForm.controls['password'].dirty)
                  ? 'textfieldRegInvalid'
                  : 'textfieldReg'
              "
              (keyup)="keyLogin($event)" />
            <mat-icon matSuffix (click)="hide = !hide">{{
              hide ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </mat-form-field>
          <ng-container *ngIf="loginForm.controls['password'].touched || loginForm.controls['password'].dirty">
            <mat-error *ngIf="!loginForm.controls['password'].value"
              >Password is Required</mat-error
            >
            <mat-error
              *ngIf="
                loginForm.controls['password'].invalid &&
                loginForm.controls['password'].value?.length > 0
              "
              >Password is Invalid</mat-error
            >
          </ng-container>
        </div>
      </div>
    </form>
    <div class="flexSpaceBetween padding-top20">
      <div class="col">
        <mat-checkbox [(ngModel)]="rememberMe" class="padding-left20"
          >Remember Me</mat-checkbox
        >
      </div>
      <div style="flex-basis: 5%"></div>
      <div class="col">
        <label class="label-fpw" (click)="goTo('recoverPassword')"
          ><a class="sidebar-item" routerLink="/recover-password">
            Forgot Password
          </a></label
        >
      </div>
    </div>

    <div class="flex padding-left padding padding-top40">
      <button matRipple class="signin-btn" (click)="login()">
        <label class="btn-font">Sign In</label>
      </button>
    </div>

    <div class="flex padding-left padding-top-16">
      <button matRipple class="google-signin-btn" (click)="googleLogin()">
        <label class="btn-font-google"
          ><img src="../../../assets/images/forma/google-logo.png" /> Sign in
          with Google</label
        >
      </button>
    </div>

    <div class="flexCenter padding-top40" *ngIf="regFlag">
      <label class="no-account">Don't have an account yet? </label>
      <label class="label-clickable">
        <a class="sidebar-item" routerLink="/register"> Sign up here </a>
      </label>
    </div>
  </mat-card>
</div>
