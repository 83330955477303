<app-wrapper-background>
  <div class="email-sent-container">
    <img src="../../../../assets/images/forma/Forma-logo-white.svg" />
    <mat-card class="container">
      <div class="wrapper" id="email">
        <h2 class="header">Email sent</h2>
        <h4 *ngIf="type == 'email-sent'">
          <span class="description">Click the link that was sent to your email to complete the
          verification process. You will only be able to access the dashboard
          once you are verified.</span>
        </h4>
        <h4 class="description" *ngIf="type == 'email-resetpw'">
          <span class="description">We’ve sent you instructions to recover your password in your email.</span>
        </h4>
        <a class="btn-next" routerLink="/login">
          <label class="btn-font"> Back to log in </label>
        </a>
        <p class="other">
          Did not receive the email? Check your spam or junk folder
        </p>
      </div>
    </mat-card>
  </div>
</app-wrapper-background>
