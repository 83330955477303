<div class="recent-info-container">
  <button matRipple class="close" (click)="close()">
    <img src="../../../../assets/images/close.svg" alt="" srcset="" />
  </button>
  <div class="infos">
    <h2 class="title wrap-text">{{ datum.name }}</h2>

    <div class="access">
      <h5 class="title">Who can access this {{ datum.type }}</h5>

      <mat-chip-list #userGroup class="item-container">
        <mat-chip class="item" *ngFor="let member of userGroups"
          >{{ member?.group_name || member?.groupName || member }}
        </mat-chip>
        <mat-chip class="item" *ngFor="let member of users"
          >{{ member?.email || member?.name || member }}
        </mat-chip>
      </mat-chip-list>

      <button matRipple class="manage-permission" (click)="openSharingPermission()" *ngIf=" !(datum?.workflow?.privacyType && datum?.workflow?.privacyType != 'PUBLIC')">
        Manage sharing permission
      </button>
    </div>

    <div class="properties">
      <h5 class="title">{{ datum.type | titlecase }} Properties</h5>

      <div class="grid-data">
        <p class="datum-type">Type:</p>
        <p class="datum">{{ datum.type | titlecase }}</p>
        <p class="datum-type">Owner:</p>
        <p class="datum">{{ datum.owner }}</p>
        <p class="datum-type">Modified:</p>
        <p class="datum">
          {{ datum.updatedAtFormatted.split(",")[0] }} by
          {{ datum.updatedByName }}
        </p>
        <p class="datum-type">Opened:</p>
        <p class="datum"  *ngIf="datum.openedAtFormatted;else na">
          {{ datum.openedAtFormatted.split(",")[0] }} by
          {{ datum.openedByName }}
        </p>
        <p class="datum-type">Created:</p>
        <p class="datum">
          {{ datum.createdAtFormatted.split(",")[0] }} by {{ datum.owner }}
        </p>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div
    *ngIf="
      datum.fileExtension == 'pdf' && datum.type !== 'folder';
      else imageView
    "
    class="preview"
  >
    <pdf-viewer
      [src]="pdfSrc"
      [render-text]="true"
      class="pdf"
      [fit-to-page]="true"
      [original-size]="false"
      [autoresize]="true"
    ></pdf-viewer>
  </div>
  <ng-template #imageView>
    <div class="image-preview">
      <img [src]="pdfSrc" alt="" />
    </div>
  </ng-template>
</div>

<ng-template #na>
  <p class="datum">N/A</p>
</ng-template>