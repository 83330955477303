<div class="container-review">
  <app-spinner></app-spinner>
  <div class="title-contianer">
    <h3 class="review__title">Review & send</h3>
    <h5 class="review__subtitle">Please review if all details are correct</h5>
  </div>
  <hr />
  <div class="document-details">
    <div class="document-details__privacy">
      <h3 class="document-details__title">
        Document privacy
        <img
          src="../../../../../assets//images/template-icons/question-mark.svg"
          alt=""
          matTooltip="Confidential - can be viewed by parties involved only
					Restricted - can be viewed by all signed in users
					Public - can be viewed by the general public"
        />
      </h3>
      <mat-radio-group
        aria-labelledby="privacy-options"
        class="privacy-options"
        [(ngModel)]="privacy"
      >
        <mat-radio-button
          class="privacy-option"
          [value]="'CONFIDENTIAL'"
          selected
        >
          Confidential
        </mat-radio-button>
        <mat-radio-button class="privacy-option" [value]="'RESTRICTED'">
          Restricted
        </mat-radio-button>
        <mat-radio-button class="privacy-option" [value]="'PUBLIC'">
          Public
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <h3 class="document-details__title">
      Document details
      <img
        src="../../../../../assets//images/template-icons/check-box.svg"
        alt=""
        (click)="goBack(2)"
      />
    </h3>
    <div class="document-details__content">
      <h5>
        Document Title: <span>{{ data.name }}</span> (<span
          class="link"
          (click)="goBack(4)"
          >Preview</span
        >)
      </h5>
    </div>
    <div class="document-details__content">
      <h5>
        Document Description: <span>{{ data.description }}</span>
      </h5>
    </div>
  </div>
  <hr />
  <div class="email-details">
    <h3 class="email-details__title">Email</h3>
    <div class="email-details__inputs">
      <div class="email-details__input">
        <mat-label>Subject</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input type="text" matInput [(ngModel)]="subject" />
        </mat-form-field>
      </div>
      <div class="email-details__input">
        <mat-label>Message (optional)</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <textarea type="text" matInput [(ngModel)]="message"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <hr />
  <div class="party-details">
    <h3 class="party-details__title">
      Parties
      <img
        src="../../../../../assets//images/template-icons/check-box.svg"
        alt=""
        (click)="goBack(3)"
      />
    </h3>
    <app-party-list [parties]="data?.workflow?.parties ?? []"></app-party-list>
  </div>

  <div class="automatic-reminders">
    <mat-checkbox
      #send
      [checked]="!!data?.workflow?.reminders"
      (change)="clearReminders($event)"
      >Send automatic reminders</mat-checkbox
    >
    <app-automatic-reminders *ngIf="send.checked"></app-automatic-reminders>
  </div>

  <div class="buttons-container">
    <button class="back" (click)="goBack(4)">Back</button>
    <button class="next" (click)="done()">Send</button>
  </div>
</div>
