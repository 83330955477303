
<div class="wrapper">
  <div class="content-wrapper">
    <div class="container" *ngIf="!isEmailSent; else emailSent">
      <mat-card class="container-reg">
        <div class="flexGrid">
          <h2 class="heading2">Create Your Account</h2>
          <h4 class="heading4">Enter your account details below</h4>
          <img class="logo" src="../../../assets/images/logo-sign-in.svg" alt="" />
        </div>
        <form name="signUpForm" [formGroup]="signUpForm">
          <div class="flex">
            <mat-label class="labelReg">Email</mat-label>
            <mat-form-field class="form" floatLabel="never" appearance="fill">
              <input
                type="email"
                placeholder="Enter your email"
                matInput
                formControlName="email"
                class="textfieldReg"
                (ngModelChange)="errMsg = ''"
                [ngClass]="{
                  'textfieldreg-error':
                    signUpForm.controls['email'].invalid &&
                    signUpForm.controls['email'].touched
                }"
                required
              />
              <mat-error
                class="padding-top20"
                *ngIf="signUpForm.controls['email'].invalid"
              >
                E-mail address format is invalid.
              </mat-error>
            </mat-form-field>
            <span style="color: red; padding-top: 10px" *ngIf="errMsg">
              {{ errMsg }}
            </span>
          </div>
    
          <div class="flex-pair">
            <div class="pair">
              <mat-label class="labelReg">First name</mat-label>
              <mat-form-field class="form" floatLabel="never" appearance="fill">
                <input
                  formControlName="firstName"
                  placeholder="First name"
                  matInput
                  class="textfieldReg-pair"
                  [ngClass]="{
                    'textfieldreg-error':
                      signUpForm.controls['firstName'].invalid &&
                      signUpForm.controls['firstName'].touched
                  }"
                />
                <mat-error
                  class="padding-top20"
                  *ngIf="signUpForm.controls['firstName'].invalid"
                >
                  Name is not valid
                </mat-error>
              </mat-form-field>
            </div>
    
            <div class="pair">
              <mat-label class="labelReg">Last name</mat-label>
              <mat-form-field class="form" floatLabel="never" appearance="fill">
                <input
                  formControlName="lastName"
                  placeholder="Last name"
                  matInput
                  class="textfieldReg-pair"
                  [ngClass]="{
                    'textfieldreg-error':
                      signUpForm.controls['lastName'].invalid &&
                      signUpForm.controls['lastName'].touched
                  }"
                />
                <mat-error
                  class="padding-top20"
                  *ngIf="signUpForm.controls['lastName'].invalid"
                >
                  Name is not valid
                </mat-error>
              </mat-form-field>
            </div>
          </div>
    
          <!-- <div class="flex">
            <mat-label class="labelReg">Phone Number</mat-label>
            <mat-form-field class="form" floatLabel="never" appearance="fill">
              <input
                type="tel"
                placeholder="+63917 123 4567"
                matInput
                class="textfieldReg"
                formControlName="phoneNumber"
                [ngClass]="{
                  'textfieldreg-error':
                    signUpForm.controls['phoneNumber'].invalid &&
                    signUpForm.controls['phoneNumber'].touched
                }"
                
              />
              <mat-error
                class="padding-top20"
                *ngIf="signUpForm.controls['phoneNumber'].invalid"
              >
                Phone number is not valid
              </mat-error>
            </mat-form-field>
          </div> -->
    
          <div class="flex">
            <mat-label class="labelReg">Password</mat-label>
            <mat-form-field class="form" floatLabel="never" appearance="fill">
              <input
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
                placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
                matInput
                class="textfieldRegPword"
                [ngClass]="{
                  'textfieldreg-error':
                    signUpForm.controls['password'].invalid &&
                    signUpForm.controls['password'].dirty
                }"
              />
              <mat-icon matSuffix (click)="hide = !hide">{{
                hide ? "visibility_off" : "visibility"
              }}</mat-icon>
            </mat-form-field>
            <mat-error
              class=""
              *ngIf="signUpForm.controls['password'].invalid &&signUpForm.controls['password'].dirty"
            >
              Password must be at least 8 characters<br />
              Contains at least 1 lower case<br />
              Contains at least 1 upper case<br />
              Contains at least 1 number<br />
              Contains at least 1 special character
            </mat-error>
          </div>
    
          <div class="flex">
            <mat-label class="labelReg">Confirm Password</mat-label>
            <mat-form-field class="form" floatLabel="never" appearance="fill">
              <input
                [type]="hideConfirm ? 'password' : 'text'"
                placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
                matInput
                class="textfieldRegPword"
                formControlName="confirmPassword"
                [ngClass]="{
                  'textfieldreg-error':
                    signUpForm.controls['confirmPassword'].dirty &&
                    signUpForm.controls['password'].value !==
                      signUpForm.controls['confirmPassword'].value
                }"
              />
              <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
                hideConfirm ? "visibility_off" : "visibility"
              }}</mat-icon>
              <mat-error class="padding-top20" style="color: red">
                Password does not match
              </mat-error>
            </mat-form-field>
          </div>
    
          <div class="checkbox-group">
            <mat-checkbox class="padding-left20" formControlName="isAgree">
            </mat-checkbox>
            <p>
              I agree to the
              <a
                class="emphasize"
                href="https://signsecureapp.unawa.asia/terms-and-conditions"
                target="_blank"
                >Terms of Use</a
              >
              and have read and consent to the processing<br />
              of personal data in accordance with the
              <a
                class="emphasize"
                href="https://signsecureapp.unawa.asia/privacy-policy"
                target="_blank"
                >Privacy Policy</a
              >
              of SignSecure.
            </p>
          </div>
    
          <div class="flex padding-top20">
            <button
              matRipple
              class="reg-btn"
              (click)="verify('email-sent')"
              type="submit"
              
            >
              <label class="btn-font">Sign Up</label>
            </button>
          </div>
        </form>
        <div class="padding-top40">
          <label>Already have an account? </label>
          <label class="label-clickable emphasize" routerLink="/login">Login</label>
        </div>
      </mat-card>
      <div class="image-carousel">
        <ng-image-slider
          [images]="imnageCarousel"
          [infinite]="true"
          [imagePopup]="false"
          [imageSize]="{ width: '100%', height: '100%' }"
          [showArrow]="false"
          [autoSlide]="5"
          #nav
        ></ng-image-slider>
      </div>
    </div>
  </div>
</div>


<ng-template #emailSent>
  <app-email-sent [type]="'email-sent'"></app-email-sent>
</ng-template>
