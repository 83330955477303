<div>
  <mat-card class="container-maintainance">
    <img *ngIf="!isPhoneWidth && !isTabletWidth" mat-card-image src="../../../assets/images/logo-maintainance.svg" alt="" />
    <mat-card-title>ForMA is currently undergoing <br/>maintenance.<br/>
      We apologize for the <br/>inconvenience.</mat-card-title>
    <mat-card-subtitle>Thank you for your patience</mat-card-subtitle>
    <mat-card-actions>
      <div class="social-title">We are in social</div>
      <a href="https://web.facebook.com/unawa.asia" target="_blank" mat-icon-button>
        <img
          src="../../../../assets/images/socials/facebook.svg"
        />
      </a>
      <a href="https://www.instagram.com/unawa.asia" target="_blank" mat-icon-button>
        <img
          src="../../../../assets/images/socials/instagram.svg"
        />
      </a>
      <a href="https://www.tiktok.com/@unawa.asia" target="_blank" mat-icon-button>
        <img
          src="../../../../assets/images/socials/tiktok.svg"
        />
      </a>
      <a href="https://www.linkedin.com/company/unawa/" target="_blank" mat-icon-button>
        <img
          src="../../../../assets/images/socials/linkedin.svg"
        />
      </a>
    </mat-card-actions>
  </mat-card>
</div>