<app-spinner (compLoaded)="spinnerLoaded = $event"></app-spinner>
<div [ngClass]="{ 'mobile-drawer-overlay': sideNavToggle }" (click)="sideNavToggle && isPhoneWidth ? toggleSideNav() : null">
  <div
    class="container"
    [ngClass]="{ 'mobile-overlay-active': sideNavToggle }"
    *ngIf="spinnerLoaded"
    [ngStyle]="{ overflow: (isLoading | async) ? 'hidden' : 'auto' }"
  >
    <router-outlet></router-outlet>
  </div>
</div>

<!-- <ng-container
  *ngIf="
    menuType == 'folders-and-files' &&
    !isSearch &&
    !modifyFile &&
    !viewPdf &&
    spinnerLoaded
  "
>
  <div class="list-wrapper">
    <app-document-tree></app-document-tree>
    <router-outlet></router-outlet>
  </div>
</ng-container> -->
