import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.sass'],
})
export class AdminMenuComponent implements OnInit {
  selected: string = '';
  readonly hideItem = environment.flag.hideSidebarMenu;
  @Output() toggleSideNavEvent = new EventEmitter<boolean>();
  constructor(
    private _navigation: NavigationService,
    private _fileActionService: FileActionService,
    private _router: Router
  ) {
    this._navigation.location$.subscribe(
      (location) => (this.selected = location)
    );
  }

  ngOnInit(): void {}

  updateLocation(location: string) {
    this._router.navigateByUrl(location);
    this._navigation.updateLocation(location);
    this._fileActionService.setIsModify(false);
  }

  toggleSideNav() {
    this.toggleSideNavEvent.emit(false);
  }
}
