<app-spinner></app-spinner>
<div class="pdf-viewer-container">
  <div class="pdf-viewer-nav" *ngIf="!isPhoneWidth && !isTabletWidth">
    <div class="left">
      <button (click)="goBack()">
        <img
          src="../../../assets//images/icons/back.svg"
          alt=""
          class="back-button" />
      </button>
      <button matRipple class="cta-buttons" (click)="download()">
        <img src="../../../assets//images/icons/download-white.svg" alt="" />
        <p>Download</p>
      </button>
      <!-- <button matRipple class="cta-buttons" *ngIf="showSignatureButton">
        <img src="../../../assets//images/sidebar/signature-white.svg" alt="" />
        <p>Signature</p>
      </button> -->
      <button matRipple class="cta-buttons"  (click)="useSignSecure()" *ngIf="showSignSecureButton">
      <p>Use signsecure</p>
      </button>
    </div>
    <div class="center">
      <button (click)="print()">
        <img [style.mask-image]="'url(../../../assets//images/template-icons/printer.svg)'" alt="" />
      </button>
      <button (click)="zoomLevel('in')">
        <img [style.mask-image]="'url(../../../assets//images/icons/zoom-in.svg)'" alt="" />
      </button>
      <button (click)="zoomLevel('out')">
        <img [style.mask-image]="'url(../../../assets//images/icons/zoom-out.svg)'" alt="" />
      </button>
      <select name="" id="" [(ngModel)]="zoom">
        <option value="0.25">25%</option>
        <option value="0.5">50%</option>
        <option value="0.75">75%</option>
        <option value="1">100%</option>
        <option value="1.25">125%</option>
        <option value="1.5">150%</option>
        <option value="1.75">175%</option>
        <option value="2">200%</option>
        <option value="2.25">225%</option>
        <option value="2.5">250%</option>
        <option value="2.75">275%</option>
        <option value="3">300%</option>
      </select>
      <div class="divider"></div>
      <button (click)="pageNavi('up')">
        <img
        [style.mask-image]="'url(../../../assets//images/icons/up.svg)'"
          alt=""
          class="back-button" />
      </button>
      <div class="page-text">{{ pageNumber }} of {{ totalPages }}</div>
      <button (click)="pageNavi('down')">
        <img
        [style.mask-image]="'url(../../../assets//images/icons/down.svg)'"
          alt=""
          class="back-button" />
      </button>
    </div>
    <div class="right">
      <button
        matRipple
        class="cta-buttons"
        (click)="openModal()"
        *ngIf="showShareButton">
        <img src="../../../assets//images/icons/share-white.svg" alt="" />
        <p>Share</p>
      </button>
      <button
        [disabled]="isPublished"
        [ngClass]="
          disableQRButton && !isPublished
            ? 'cta-buttons text-shadow'
            : 'cta-buttons text-shadow disabled'
        "
        *ngIf="showQRButton"
        (click)="showQRScreen()">
        <img src="../../../assets/images/qr-button-icon.svg" alt="" />
        <p>Add QR Code</p>
      </button>
    </div>
  </div>
  <div class="phone-nav" *ngIf="isPhoneWidth">
    <div class="left">
      <button (click)="goBack()">
        <img src="../../../assets//images/icons/back.svg" alt="" />
      </button>
    </div>
    <div class="right">
      <button (click)="openModal()" *ngIf="showShareButton">
        <img src="../../../assets//images/icons/share-white.svg" alt="" />
      </button>
      <button (click)="download()">
        <img src="../../../assets//images/icons/download-white.svg" alt="" />
      </button>
      <button (click)="useSignSecure()">
        <img src="../../../assets//images/icons/signsecure.svg" alt="" />
      </button>
    </div>
  </div>
  <div class="tablet-nav" *ngIf="isTabletWidth">
    <div class="left">
      <button class="back" (click)="goBack()">
        <img src="../../../assets//images/icons/back.svg" alt="" />
      </button>
      <button (click)="download()">
        <img src="../../../assets//images/icons/download-white.svg" alt="" />
        <span>Download</span>
      </button>
      <button (click)="useSignSecure()">
        <img src="../../../assets//images/icons/signsecure.svg" alt="" />
        <span>Add Signature</span>
      </button>
    </div>
    <div class="right">
      <button (click)="openModal()" *ngIf="showShareButton">
        <img src="../../../assets//images/icons/share-white.svg" alt="" />
      </button>
    </div>
  </div>
  <div class="content" #content>
    <div
      class="overlay"
      [style.display]="showQRModal"
      [style.height]="overlayHeight"
      (click)="showQRScreen()"
      #overlay></div>
  </div>
  <pdf-viewer
    [src]="pdfSrc"
    [render-text]="true"
    [original-size]="true"
    [fit-to-page]="true"
    [zoom]="zoom"
    [(page)]="pageNumber"
    (after-load-complete)="afterLoadComplete($event)"
    class="pdf"
    #viewer></pdf-viewer>

    <div class="page-nav" *ngIf="isPhoneWidth || isTabletWidth">
      <button (click)="pageNavi('up')">
        <img src="../../../assets//images/icons/up.svg" />
      </button>
      <div class="page-number">{{ pageNumber }} of {{ totalPages }}</div>
      <button (click)="pageNavi('down')">
        <img src="../../../assets//images/icons/down.svg" />
      </button>
    </div>

  <div class="qr-menu" [style.display]="showQRModal">
    <img
      class="qr-menu__image"
      src="../../../assets/images/modal-qr-button.svg"
      alt="" />

    <h4>Set QR Code Position</h4>
    <div class="select-field">
      <label for="qr-postion">QR code position</label>
      <mat-select
        name="qr-position"
        (selectionChange)="qrPositionChanged($event)"
        [(ngModel)]="qrPosition">
        <mat-option value="top-right" selected>Top Right</mat-option>
        <mat-option value="top-left">Top Left</mat-option>
        <mat-option value="top-center">Top Center</mat-option>
        <mat-option value="bottom-right">Bottom Right</mat-option>
        <mat-option value="bottom-left">Bottom Left</mat-option>
        <mat-option value="bottom-center">Bottom Center</mat-option>
      </mat-select>
      <span
        >Note: QR Codes will be added to every document pages and its position
        can't be change once saved</span
      >
    </div>
    <div class="cta-buttons">
      <button class="cancel" (click)="showQRScreen()">Cancel</button>
      <button class="save" (click)="save()">Save</button>
    </div>
  </div>
</div>
