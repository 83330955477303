<app-spinner></app-spinner>
<app-wrapper-background>
  <div class="reset-password-container">
    <form name="passwordReset" [formGroup]="passwordReset">
      <mat-card class="container">
        <div class="flexGrid">
          <h2 class="heading2">Reset your password</h2>
          <h4 class="heading4">
            Please enter a new password that you haven't used before.
          </h4>
        </div>
        <div class="flex">
          <div class="flex">
            <mat-label class="labelReg">Password</mat-label>
            <mat-form-field class="form" floatLabel="never" appearance="fill">
              <input
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
                placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
                matInput
                class="textfieldRegPword"
                (ngModelChange)="checkPassword()"
                [ngClass]="{
                  'textfieldreg-error':
                    passwordReset.controls['password'].invalid &&
                    passwordReset.controls['password'].dirty
                }" />
              <mat-icon matSuffix (click)="hide = !hide">{{
                hide ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </mat-form-field>
            <mat-error
              *ngIf="
                passwordReset.controls['password'].invalid &&
                passwordReset.controls['password'].dirty &&
                passwordReset.controls['password'].value !== ''
              ">
              Password must be at least 8 characters<br />
              Contains at least 1 lower case<br />
              Contains at least 1 upper case<br />
              Contains at least 1 number<br />
              Contains at least 1 special character.
            </mat-error>
            <mat-error *ngIf="passwordReset.get('password')?.hasError('required') && passwordReset.get('password')?.dirty">
              Password is required.
            </mat-error>
          </div>

          <div class="flex">
            <mat-label class="labelReg">Confirm Password</mat-label>
            <mat-form-field class="form" floatLabel="never" appearance="fill">
              <input
                [type]="hideConfirm ? 'password' : 'text'"
                placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
                matInput
                class="textfieldRegPword"
                formControlName="confirmPassword"
                (ngModelChange)="checkPassword()"
                [ngClass]="{
                  'textfieldreg-error':
                    passwordReset.controls['confirmPassword'].dirty &&
                    passwordReset.controls['password'].value !==
                      passwordReset.controls['confirmPassword'].value
                }" />
              <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
                hideConfirm ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </mat-form-field>
            <span style="color: red" *ngIf="!isPwordMatch">{{
              pwordErrorMsg
            }}</span>
            <mat-error *ngIf="passwordReset.get('confirmPassword')?.hasError('required') && passwordReset.get('confirmPassword')?.dirty">
              Confirm password is required.
            </mat-error>
          </div>
        </div>
        <div class="flex padding-top20">
          <button
            matRipple
            class="reg-btn"
            type="submit"
            (click)="changePassword()">
            <label class="btn-font">Change Password</label>
          </button>
        </div>
      </mat-card>
    </form>
  </div>
</app-wrapper-background>
