<main>
  <aside>
    <div class="title">
      <img
        src="../../../assets/images/forma/format-logo.svg"
        alt=""
        class="scr" />
    </div>
    <div class="content">
        <h4 class="content__title">Welcome Guest!</h4>
        <p class="content__description">
          We're thrilled to have you on board for a seamless and efficient
          e-signature experience. Our user-friendly platform has been designed
          with you in mind, ensuring a hassle-free journey from start to finish.
        </p>
    </div>
    <div class="footer">
      <a
        class="footer__emphasize"
        href="https://signsecureapp.unawa.asia/terms-and-conditions"
        target="_blank"
        >Terms & Conditions</a
      >
      |
      <a
        class="footer__emphasize"
        href="https://signsecureapp.unawa.asia/privacy-policy"
        target="_blank"
        >Privacy Policy</a
      ><br />
      © 2024
      <a
        class="footer__emphasize"
        href="https://www.unawa.asia/"
        target="_blank"
        >UNAWA</a
      >
      All rights reserved<br />
      <div class="version">Version: {{ version }}</div><br />
    </div>
  </aside>
  <content>
    <h3>You are invited to review and sign the following documents:</h3>
    <app-table
      [resultsLength]="resultLength"
      [title]="''"
      [columns]="cols"
      [data]="data"
      [paginate]="true"
      [canOpenFile]="true"
      [hideTitle]="true"
      [rowFunction]="openPdf"></app-table>
      
  </content>
</main>

<div class="mobile-container">
  <div class="header">
    <img class="logo" src="../../../assets/images/unawa-forma-logo-blue.svg" alt="" />
  </div>
  <div class="body">
  <div class="contents">
    <h4 class="content__title">Welcome Guest!</h4>
    <p class="content__description">
      We're thrilled to have you on board for a seamless and efficient
      e-signature experience. Our user-friendly platform has been designed
      with you in mind, ensuring a hassle-free journey from start to finish.
    </p>
  </div>

  <h3 class="header-label">You are invited to review and sign the following documents:</h3>

  <app-tablet-table
    [itemTemplate]="inbox_row"
    [title]="''"
    [data]="data"
  ></app-tablet-table>
  </div>

  <div class="footer">
    <a
      class="footer__emphasize"
      href="https://signsecureapp.unawa.asia/terms-and-conditions"
      target="_blank"
      >Terms & Conditions</a
    >
    |
    <a
      class="footer__emphasize"
      href="https://signsecureapp.unawa.asia/privacy-policy"
      target="_blank"
      >Privacy Policy</a
    ><br />
    © 2024
    <a
      class="footer__emphasize"
      href="https://www.unawa.asia/"
      target="_blank"
      >UNAWA</a
    >
    All rights reserved<br />
    <div class="version">Version: {{ version }}</div><br />
  </div>
</div>

<ng-template #inbox_row let-data>
  <div class="inbox-row" (click)="openPdf(null, data)">
    <div class="inbox-content">
      <p class="sublabel">{{data.title}}</p>
      <p class="sublabe2 date_created">{{data.date_created}}</p>
      <p class="sublabe2"><span class="label">Recent Action: </span>{{data.recent_action}}</p>
      <p class="sublabe2"><span class="label">Last Update: </span>{{data.last_update}}</p>
      <p class="sublabe2"><span class="label">Status: </span>{{data.status}}</p>
    </div>
  </div>
</ng-template>