<div class="upload-container">
	<div class="top">
		<div class="left">
			<h4 class="header">Upload your document</h4>
			<a class="sub-header">File must be in PNG, JPG, or PDF format.</a>
		</div>
		<div class="right">
			<!-- <a>Share this document with your team?</a>
      <mat-checkbox></mat-checkbox>
      <img src="../../../../../assets/images/template-icons/details.png" /> -->
		</div>
	</div>
	<div class="body">
		<ng-container *ngIf="!uploadFile; else fileUpload">
			<div
				class="drag-drop-container"
				appDragDropFileUpload
				(fileDropped)="dragover($event)"
			>
				<div class="container">
					<img src="../../../../../assets//images/upload.svg" alt=""/>
					<h4>Drag and Drop to upload</h4>
				</div>
				<div class="or-container">
					<p class="or">Or select from your computer</p>
				</div>
				<div class="container-button">
					<button (click)="upload()">Add file</button>
				</div>
			</div>

			<input
				type="file"
				id="fileUpload"
				name="fileUpload"
				style="display: none"
				(change)="onFileSelected($event)"
				accept=".pdf,.png,.jpg"
			/>
		</ng-container>
	</div>
	<div class="bottom">
		<button
			[disabled]="!uploadFile"
			[ngClass]="uploadFile ? 'button-active' : 'button-inactive'"
			(click)="next()"
		>
			Set Details
		</button>
	</div>
</div>

<ng-template #fileUpload>
	<div class="upload">
		<img
			alt=""
			class="upload-image"			
		/>
		<a class="sub-header">Your file was successfully uploaded!</a>
		<a class="filesize">File size: {{ size }}</a>
		<img
			src="../../../../../assets/images/template-icons/templ-image.png"
			alt=""
		/>
		<a class="pick-one" (click)="pickAnother()"
			><img
				src="../../../../../assets/images/template-icons/pick-new.png"
			/>Pick another document</a
		>
	</div>
</ng-template>
