import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FileDataModel } from 'src/app/@shared/models/file-data.model';
import { FileTableModel } from 'src/app/@shared/models/file-table.model';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.sass'],
})
export class DocumentsComponent implements OnInit, AfterViewInit {
  myfilesData: FileTableModel[] = [];
  groupfilesData: FileTableModel[] = [];
  sharedfilesData: FileTableModel[] = [];

  isTabletWidth = false;
  isPhoneWidth = false;

  filesCols: string[] = [];
  hasCheckBox: boolean = environment.flag.multiFileSelect;
  myResultsLength: number = 0;
  groupResultsLength: number = 0;
  sharedResultsLength: number = 0;
  isGroupDataLoaded: boolean = false;
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  subs: Subscription = new Subscription();
  groupDocumentFlag: boolean = environment.flag.groupDocuments;
  constructor(
    private _filesService: FilesService,
    private _fileActionService: FileActionService,
    private router: Router
  ) {}

  ngAfterViewInit() {
    this.tabGroup.selectedIndex = this._fileActionService.selectedTab;
    this.tabGroup.realignInkBar();
  }

  changeTab(data: any) {
    this._fileActionService.selectedTab = data.index;
    switch (data.index) {
      case 0:
        this.loadMyDocs();
        break;

      case 1:
        this.loadGroupDocs();
        break;

      case 2:
        this.loadShareedDocs();
        break;

      default:
        break;
    }
  }

  ngOnInit(): void {
    if (screen.width >= 280 && screen.width <= 600) {
      this.isPhoneWidth = true;
      this.filesCols = [...this._filesService.filesColsMobile];
    } else if (screen.width >= 600 && screen.width <= 1368) {
      this.isTabletWidth = true;
      this.filesCols = [...this._filesService.filesColsMobile];
    } else {
      this.isPhoneWidth = false;
      this.isTabletWidth = false;
      this.filesCols = [...this._filesService.filesCols];
    }

    this.loadMyDocs();
  }

  openPdf(data: FileTableModel) {
    if (data.file_type === 'folder' || data.file_type === 'back') return;

    let url =
      data?.actions?.choices?.download?.path || data?.actions?.download?.path;

    this._fileActionService.pdfSrc = url;
    this._fileActionService.file = data;
    this._fileActionService.setIsViewFile(true);
    this.router.navigateByUrl(`file-view/${data.fileExtension}/${data.id}`);
  }
  loadMyDocs() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.subs = this._filesService.getUserFiles().subscribe(data => {
      const formattedData = this._filesService.formatFile(data.data,false,false);
      this.myfilesData = formattedData;
      this.myResultsLength = formattedData.length;
    });
  }
  loadGroupDocs() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.subs = this._filesService.getGroupFiles().subscribe(data => {
      const formattedData = this._filesService.formatFile(
        data.data,
        false,
        true
      );
      this.groupfilesData = formattedData;
      this.groupResultsLength = formattedData.length;
      this.isGroupDataLoaded = true;
    });
  }
  loadShareedDocs() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.subs = this._filesService
      .getSharedDocuments()
      .subscribe((data) => {
        const formattedData = this._filesService.formatFile(data.data, false, true);
        this.sharedfilesData = formattedData;
        this.sharedResultsLength = formattedData.length;
      });
  }
}
