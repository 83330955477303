<div class="done-container">
  <div class="done">
    <img
      alt=""
      [style.mask-image]="
        'url(' +
        (data?.image
          ? data.image
          : '../../../assets/images/document-done.svg') +
        ')'
      " />
    <div class="text">
      <h3 class="title">{{ data?.title ? data.title : 'Document signed' }}</h3>
      <span
        class="subtitle"
        [innerHTML]="
          data?.description
            ? data.description
            : 'The sender will be notified and will receive the signed document'
        "></span>
    </div>
    <div class="buttons">
      <button class="download" (click)="download()">Download document</button>
      <button class="return" (click)="return()">
        Return to {{ lastLocation }}
      </button>
    </div>
  </div>
</div>
