import { NestedTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { switchMap } from 'rxjs';
import { BreadcrumbsService } from 'src/app/@shared/services/breadcrumbs.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import treeData from '../../../../dummy-data/file-tree.json';
interface TreeData {
  name: string;
  children?: TreeData[];
  parent?: string;
}
@Component({
  selector: 'app-document-tree',
  templateUrl: './document-tree.component.html',
  styleUrls: ['./document-tree.component.sass'],
})
export class DocumentTreeComponent implements OnInit, AfterViewInit, OnChanges {
  treeControl = new NestedTreeControl<TreeData>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<TreeData>();
  node: any = null;

  recentTreeControl = new NestedTreeControl<TreeData>((node) => node.children);
  recentDataSource = new MatTreeNestedDataSource<TreeData>();
  recentNode: any = null;

  @ViewChild('allFoldersFiles')
  folderAndFilesPanel!: MatExpansionPanel;

  @Output()
  OpenFolder = new EventEmitter();

  constructor(
    private _fileService: FilesService,
    private _breadcrumbs: BreadcrumbsService
  ) {}

  ngOnInit(): void {
    this._fileService.getAllFolder().subscribe((data) => {
      const format = this._fileService.formatFolder(data.data.data);
      this._fileService.setFolderAsync(format);
    });

    this.dataSource.data = treeData;
    this.recentDataSource.data = treeData;
    this._fileService.folderAsync$.subscribe((data) => {
      this.dataSource.data = data;
      this.recentDataSource.data = [...data].splice(0, 10);
      this.refreshTree();
      if (this._fileService.node) {
        const dataNode: any = this.findNode(data, this._fileService.node);

        if (dataNode?.parent) {
          this.treeControl.expandDescendants(dataNode);
        } else {
          this.treeControl.expand(dataNode);
        }
      }
    });
  }

  findNode(nodes: any[], node: any) {
    for (let n in nodes) {
      const ctr = n as unknown as number;
      if (nodes[ctr]?.id === node.id) {
        return nodes[ctr];
      }

      if (nodes[ctr].children) {
        const result: any = this.findNode(nodes[ctr].children, node);
        if (result !== null) return result;
      }
    }

    return null;
  }

  refreshTree() {
    let _data = this.dataSource.data;
    this.dataSource.data = [];
    this.dataSource.data = _data;
  }

  ngAfterViewInit() {
    this.folderAndFilesPanel.open();
  }

  ngOnChanges(changes: any): void {}

  hasChild = (_: number, node: any) =>
    !!node.children && node.children.length > 0;

  openFolder(data: any) {
    this.treeControl;
    if (this.treeControl.isExpanded(data)) {
      this.node = data;
      this._fileService.getAllFolderFiles(data.id).subscribe(({ data }) => {
        const format = this._fileService.formatFilesAndFolderData(data);
        this._fileService.setFilesAndFolderAsync(format);
      });

      this._fileService.openFolderOnTree(data).subscribe((i) => {
        const format = this._fileService.formatFolder(i.data, true);
        this._breadcrumbs.emptyBreadcrumb();
        const formatNode = this._fileService.formatNode(
          this.dataSource.data,
          data,
          format
        );

        this.node = data;
        this._fileService.setFolderAsync(formatNode);
      });
    } else {
    }
    this.OpenFolder.emit();
  }

  openFolderRecent(data: any) {
    if (this.recentTreeControl.isExpanded(data)) {
      this.node = data;
      this._fileService.getAllFolderFiles(data.id).subscribe(({ data }) => {
        const format = this._fileService.formatFilesAndFolderData(data);
        this._fileService.setFilesAndFolderAsync(format);
      });

      this._fileService.openFolderOnTree(data).subscribe((i) => {
        const format = this._fileService.formatFolder(i.data, true);
        this._breadcrumbs.emptyBreadcrumb();
        const formatNode = this._fileService.formatNode(
          this.dataSource.data,
          data,
          format
        );
        this.node = data;
        this._fileService.setFolderAsync(formatNode);
      });
    } else {
    }
  }
}
