import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileActionService } from '../services/file-action.service';
import { FilesService } from '../services/files.service';
import { NavigationService } from '../services/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.sass'],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @Input() isSearch: boolean = false;
  @Input() searchResult: any;
  count: number = 12;
  keyword: string = 'Senate of the Phils';
  prevPosition: string = 'Dashboard';
  hasCheckBox: boolean = environment.flag.multiFileSelect;
  filesCols: string[] = [];
  filesData: Object[] = [];
  sub: Subscription = new Subscription();

  isTabletWidth = false;
  isPhoneWidth = false;
  
  constructor(
    private _fileService: FilesService,
    private _fileActionService: FileActionService,
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _router:Router,
    private _location: Location,
    public _dialog: MatDialog,
  ) {
    this.prevPosition = this._navigationService.getLastLocation();
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    if (screen.width >= 280 && screen.width <= 600) {
      this.isPhoneWidth = true;
      this.filesCols = this._fileService.filesColsMobile;
    } else if (screen.width >= 600 && screen.width <= 1368) {
      this.isTabletWidth = true;
      this.filesCols = this._fileService.filesColsMobile;
    } else {
      this.isPhoneWidth = false;
      this.isTabletWidth = false;
      this.filesCols = this._fileService.filesCols;
    }

    this._fileService.searchResults$.subscribe((data) => {
      this.filesData = data.data;
      this.count = this.filesData.length;
      this.keyword = data.searchQuery;
    });

    this.sub.add(
      this._route.params
        .pipe(
          switchMap(({ query }) => {
            this.keyword = query;
            return this._fileService.searchFiles(query);
          })
        )
        .subscribe(({ data }: any) => {
          this.filesData = this._fileService.formatSearchResults(data.data || []);
          this.count = data.total;
        })
    );
  }
  backMenu() {
    //this._router.navigateByUrl('/dashboard');
    this._location.back();
  }

  openPdf = (data: any)=> {
    if (data.file_type === 'folder' || data.file_type === 'back') return;
    let url =
      data?.action?.choices?.download?.path ||
      data?.actions?.choices?.download?.path ||
      data?.action?.download?.path ||
      data?.actions?.download?.path;

    if (
      data.fileExtension == 'docx' ||
      data.fileExtension == 'doc' ||
      data.fileExtension == 'xls' ||
      data.fileExtension == 'xlsx' ||
      data.fileExtension == 'ppt' ||
      data.fileExtension == 'pptx' ||
      data.fileExtension == 'zip' ||
      data.fileExtension == 'rar' ||
      data.fileExtension == '7z'
    ) {
      let office = '';
      if (data.fileExtension == 'docx' || data.fileExtension == 'doc') {
        office = 'ms-word';
      }
      if (data.fileExtension == 'xls' || data.fileExtension == 'xlsx') {
        office = 'ms-excel';
      }
      if (data.fileExtension == 'ppt' || data.fileExtension == 'pptx') {
        office = 'ms-powerpoint';
      }

      // window.location.assign(
      //   office + ':ofv|u|' + window.location.origin + '/assets/' + url
      // );
      this._fileService.getProxyUrl(data.id).subscribe(msData => {
        this._fileService.downloadFile(data.title, msData);
      });
      return;
    }
    //url = `../../../assets/${url}`;

    if (data.fileExtension == 'mp4' || data.fileExtension === 'mp3') {
      const dialogRef = this._dialog.open(ModalComponent, {
        panelClass: 'transparent-dialog',
        backdropClass: 'dark-bg',
        data: {
          action: 'media-viewer',
          data: data,
        },
      });

      dialogRef.afterClosed().subscribe(() => {});
    } else {
      this._fileActionService.pdfSrc = url;
      this._fileActionService.file = data;
      this._fileActionService.setIsViewFile(true);
      this._router.navigateByUrl(
        `/file-view/${data.fileExtension.toLowerCase()}/${data.id}`
      );
    }
  }
}
