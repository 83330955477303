import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { FilesService } from 'src/app/@shared/services/files.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.sass'],
})
export class FileViewerComponent implements OnInit, OnDestroy {
  type: string = '';
  pdfSrc: string = '';
  id: string = '';
  subs: Subscription = new Subscription();
  msFiles = ['docx', 'xlsx', 'pptx', 'zip', 'rar', '7z'];
  mediaFiles = ['mp4', 'mp3'];

  constructor(
    private _route: ActivatedRoute,
    private fileService: FilesService,
    public dialog: MatDialog,
  ) {}
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.add(
      this._route.params.subscribe(({ id, type }) => {
        if (id && id !== '') {
          console.log({id, type})
          this.fileService.getFile(id).subscribe(({data: file}) => {
            this.type = type;
            this.id = id;

            if (this.msFiles.includes(type)) {
              this.fileService.getProxyUrl(id).subscribe(fileData => {
                this.fileService.downloadFile(file.name, fileData);
              });
            } else if(this.mediaFiles.includes(type)){
              this.dialog.open(ModalComponent, {
                disableClose: true,
                panelClass: 'transparent-dialog',
                backdropClass: 'dark-bg',
                data: {
                  action: 'media-viewer',
                  data: file,
                  close: false
                },
              });
            }
          });
        }
      })
    );
  }
}
